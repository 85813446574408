import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from "@angular/forms";

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  url = environment.url;

  constructor(private http: HttpClient) { }

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  allLocations(): Observable<any> {
    return this.http.get(`${this.url}/api/locations`, httpOptions).pipe(
      map(this.extractData),
    );
  }

  addLocation(credentials) {
    return this.http.post(`${this.url}/api/addLocation`, credentials);
  }

  removeLocation(id) {
    return this.http.delete(`${this.url}/api/location/${id}`, id);
  }

  update(credentials) {
    return this.http.patch(`${this.url}/api/location/changeInfo`, credentials);
  }

  form: FormGroup = new FormGroup({
    _id: new FormControl(null),
    branch_id: new FormControl('', Validators.required),
    branch_name: new FormControl('', Validators.required),
    __v: new FormControl(null),
  });

  initializeFormGroup() {
    this.form.setValue({
      _id: null,
      branch_id: '',
      branch_name: '',
      __v: null,
    });
  }

  populateForm(location) {
    this.form.setValue(location);
  }

}

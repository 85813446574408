import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { AdminsService } from '../../../services/admins.service';
import { LocationsService } from '../../../services/locations.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'ngx-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  locations: any;
  hide = true;

  constructor(public service: AdminsService,
    private locationsService: LocationsService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<AdminComponent>,) { }

  ngOnInit() {
    this.getLocations();
  }

  onClear() {
    this.service.form.reset();
    this.service.initializeFormGroup();
    this.notificationService.success(':: Form cleared successfully');
  }

  onSubmit() {
    if (this.service.form.valid) {
      if (!this.service.form.get('_id').value)
      this.service.register(this.service.form.value).subscribe();
      else
      this.service.update(this.service.form.value).subscribe();
      this.service.form.reset();
      this.service.initializeFormGroup();
      this.notificationService.success(':: Submitted successfully');
      this.onClose();
    }
  }

  onClose() {
    this.service.form.reset();
    this.service.initializeFormGroup();
    this.dialogRef.close();
  }

  async getLocations() {
    await this.locationsService.allLocations()
      .subscribe(res => {
        this.locations = res;
      }, err => {
        console.log(err);
      });
  }

}

<mat-toolbar color="primary">
  <span>{{service.form.controls['_id'].value?"Modify Gate Keeper":"New Gate Keeper"}}</span>
  <span class="fill-remaining-space"></span>
  <button class="btn-dialog-close" mat-stroked-button (click)="onClose()" tabIndex="-1"><mat-icon>clear</mat-icon></button>
</mat-toolbar>
<form [formGroup]="service.form" class="normal-form" (submit)="onSubmit()">
  <div class="controles-container">
    <input type="hidden" formControlName="_id">
    <input type="hidden" formControlName="__v">

        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label for="username" >Username</mat-label>
          <input matInput type="text" formControlName="username" id="username" placeholder="Username">
        </mat-form-field>

        <mat-form-field appearance="fill" style="width: 48%; float: left;">
          <mat-label for="first_name" >First Name</mat-label>
          <input matInput type="text" formControlName="first_name" id="first_name" placeholder="First Name">
        </mat-form-field>
        <mat-form-field appearance="fill" style="width: 48%; float: right;">
          <mat-label for="last_name" >Last Name</mat-label>
          <input matInput type="text" formControlName="last_name" id="last_name" placeholder="Last Name">
        </mat-form-field>


        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label  for="location">Location</mat-label>
          <mat-select formControlName="location" id="location" placeholder="Location">
            <mat-option *ngFor="let location of locations" value="{{ location.branch_name }}">{{ location.branch_name}} - {{ location.branch_id}}</mat-option>
          </mat-select>
        </mat-form-field>


        <mat-form-field appearance="fill" style="width: 100%;">
          <mat-label for="password">Password</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'"  type="{{service.form.controls['_id'].value?'hidden':'password'}}" formControlName="password" id="password" placeholder="Password">
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
          <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>

      <div class="button-row">
        <button mat-raised-button color="primary" type="submit" [disabled]="service.form.invalid">Submit</button>
        <button mat-raised-button color="warn" (click)="onClear()">Clear</button>
      </div>

  </div>

</form>

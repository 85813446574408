import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { EmployeesService } from '../../../services/employees.service';
import { LocationsService } from '../../../services/locations.service';
import { NotificationService } from '../../../services/notification.service';

@Component({
  selector: 'ngx-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {

  locations: any;
  hide = true;

  constructor(public service: EmployeesService,
    private locationsService: LocationsService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<EmployeeComponent>,) { }



  ngOnInit() {
    this.getLocations();
  }

  onClear() {
    this.service.form.reset();
    this.service.initializeFormGroup();
    this.notificationService.success(':: Form cleared successfully');
  }

  onSubmit() {
    if (this.service.form.valid) {
      if (!this.service.form.get('_id').value)
      this.service.register(this.service.form.value).subscribe();
      else
      this.service.update(this.service.form.value).subscribe();
      this.service.form.reset();
      this.service.initializeFormGroup();
      this.notificationService.success(':: Submitted successfully');
      this.onClose();
    }
  }

  onClose() {
    this.service.form.reset();
    this.service.initializeFormGroup();
    this.dialogRef.close();
  }

  async getLocations() {
    await this.locationsService.allLocations()
      .subscribe(res => {
        this.locations = res;
      }, err => {
        console.log(err);
      });
  }


}

<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()"><img src="../../../../assets/images/Logo.png" style="height: 50px;"></a>
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">

    <nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.first_name"
               [picture]="base64image">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>

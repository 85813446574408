import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from "@angular/forms";

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class GatekeepersService {

  url = environment.url;

  constructor(private http: HttpClient) { }

  private extractData(res: Response) {
    let body = res;
    return body || { };
  }

  allGatekeepers(): Observable<any> {
    return this.http.get(`${this.url}/api/gatekeepers`, httpOptions).pipe(
      map(this.extractData),
    );
  }

  register(credentials) {
    return this.http.post(`${this.url}/api/registergatekeeper`, credentials);
  }

  update(credentials) {
    return this.http.patch(`${this.url}/api/gatekeeper/changeInfo`, credentials);
  }

  changePassword(credentials) {
    return this.http.patch(`${this.url}/api/gatekeeper/changepassword`, credentials);
  }

  removeGatekeeper(id) {
    return this.http.delete(`${this.url}/api/gatekeeper/${id}`, id);
  }

  form: FormGroup = new FormGroup({
    _id: new FormControl(null),
    username: new FormControl('', Validators.required),
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    location: new FormControl('', Validators.required),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    __v: new FormControl(null),
  });

  initializeFormGroup() {
    this.form.setValue({
      _id: null,
      username: '',
      first_name: '',
      last_name: '',
      location: '',
      password: '',
      __v: null,
    });
  }

  populateForm(gatekeeper) {
    this.form.setValue(gatekeeper);
  }

}
